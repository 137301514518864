<template>
  <div>
    <Header />
    <div class="md:h-480 h-96 w-full bg-black bg-opacity-30 relative">
      <img
        src="../assets/hero-about.png"
        alt="Hero About"
        class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
      />
      <div
        class="h-full w-full flex justify-center items-center text-center text-white font-bold"
      >
        <h1 class="md:text-7xl text-4xl">Kontak</h1>
      </div>
    </div>
    <div class="md:mx-20 md:my-16 p-4">
      <GmapMap
        :center="{
          lat: -6.12046,
          lng: 106.82969,
        }"
        :zoom="19"
        map-type-id="terrain"
        class="md:h-562 h-96 w-full"
      >
        <GmapMarker
          :position="google && new google.maps.LatLng(-6.12046, 106.82969)"
          :clickable="true"
          :draggable="true"
        />
      </GmapMap>
    </div>
    <div class="md:flex flex-row w-full">
      <div
        class="md:w-1/2 w-full bg-primary md:p-24 p-8 flex flex-col divide-y"
      >
        <div class="flex flex-col gap-4 md:pb-12 pb-6">
          <h3 class="font-bold text-white">Head Quater - Jakarta</h3>
          <div class="flex flex-col gap-1">
            <div class="flex flex-row items-center gap-3">
              <font-awesome-icon
                icon="fa-solid fa-location-arrow"
                class="text-yellow"
              />
              <p class="text-white">
                Dermaga 17 Marina, Ancol, Jakarta Utara – 14430
              </p>
            </div>
            <div class="flex flex-row items-center gap-3">
              <font-awesome-icon icon="fa-solid fa-phone" class="text-yellow" />
              <p class="text-white">+62 821-2547-0902</p>
            </div>
            <div class="flex flex-row items-center gap-3">
              <font-awesome-icon
                icon="fa-regular fa-envelope"
                class="text-yellow"
              />
              <p class="text-white">info@tripwe.com</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4 md:pt-12 pt-6">
          <h3 class="font-bold text-white">Semarang</h3>
          <div class="flex flex-col gap-1">
            <div class="flex flex-row items-center gap-3">
              <font-awesome-icon
                icon="fa-solid fa-location-arrow"
                class="text-yellow"
              />
              <p class="text-white">Jl. Beton Mas Selatan No. 176 Semarang</p>
            </div>
            <div class="flex flex-row items-center gap-3">
              <font-awesome-icon icon="fa-solid fa-phone" class="text-yellow" />
              <p class="text-white">+62 819-3835-8888</p>
            </div>
          </div>
        </div>
      </div>
      <div class="md:w-1/2 w-full bg-black md:pr-24 md:pl-16 md:py-16 p-8">
        <div class="flex flex-col gap-8">
          <div class="flex flex-col gap-2">
            <h2 class="text-white font-bold text-2xl md:text-3xl">
              Leave us your info
            </h2>
            <h2 class="text-yellow font-bold text-2xl md:text-3xl">
              and we will get back to you.
            </h2>
          </div>
          <div class="flex flex-col gap-3">
            <div class="flex flex-row gap-3">
              <input type="text" class="w-full p-4" placeholder="First Name*" />
              <input type="text" class="w-full p-4" placeholder="Last Name*" />
            </div>
            <input type="text" class="w-full p-4" placeholder="Subject*" />
            <textarea
              cols="30"
              rows="5"
              placeholder="Message*"
              class="p-4"
            ></textarea>
            <button class="bg-primary text-white py-4 font-bold">
              SUBMIT NOW
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { gmapApi } from "vue2-google-maps";

export default {
  components: { Header, Footer },
  data: () => ({
    heroStyle: {
      backgroundImage: "url('./src/assets/hero-about.png')",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  }),
  computed: {
    google: gmapApi,
  },
};
</script>
